import React from 'react';
import PropTypes from 'prop-types';
import '../styles/global.scss';

const MainLayout = ({ children }) => {
    return (
        <main role="main" className="bg-black">
            {children}
        </main>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;
