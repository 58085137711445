import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const Seo = ({ title, description, lang, meta }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                        image
                    }
                }
            }
        `,
    );

    const image = site.siteMetadata.image;
    const keywords = site.siteMetadata.keywords;
    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = title || site.siteMetadata?.title;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={defaultTitle}
            titleTemplate={defaultTitle}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: keywords,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata?.author || '',
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    );
};

Seo.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default Seo;
